import {createRouter, createWebHistory} from 'vue-router';
import {userAuthenticate} from "@/functions/userAuthenticate";
import {brandConfigMap} from "@/brand";
// import {utagDataPost} from "@/functions/utagDataPost";

const routes = [
  {
    path: '/',
    redirect: getBaseRoute(),
  },
  {
    path: '/welcome',
    name: 'WelcomeView',
    component: () => import("@/views/WelcomeView.vue"),
  },
  {
    path: '/request-otp',
    name: 'RequestOtp',
    component: () => import("@/views/RequestOtp.vue"),
  },
  {
    path: '/submit-otp',
    name: 'SubmitOtp',
    component: () => import("@/views/SubmitOtp.vue"),
  },
  {
    path: '/subscription',
    name: 'SubscriptionView',
    component: () => import("@/views/SubscriptionView.vue"),
  },
  {
    path: '/confirm-subscription/:price/:interval',
    name: 'ConfirmSubscription',
    component: () => import("@/views/ConfirmSubscription.vue"),
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import("@/views/HomeView.vue"),
  },
  {
    path: '/prizes',
    name: 'PrizesView',
    component: () => import("@/views/PrizesView.vue"),
  },
  {
    path: '/leaderboard',
    name: 'LeaderboardView',
    component: () => import("@/views/LeaderboardView.vue"),
  },
  {
    path: '/settings',
    name: 'SettingsView',
    component: () => import("@/views/SettingsView.vue"),
  },
  {
    path: '/trivia',
    name: 'TriviaQuestions',
    component: () => import("@/views/TriviaQuestions.vue"),
  },
  {
    path: '/lesson-quiz/:lessonId',
    name: 'LessonQuiz',
    props: true,
    component: () => import("@/views/LessonQuiz.vue"),
  },
  {
    path: '/lesson-quiz-complete/:correctAnswers/:points',
    name: 'LessonQuizComplete',
    props: true,
    component: () => import("@/views/LessonQuizComplete.vue"),
  },
  {
    path: '/quiz-complete/:correctAnswers/:time',
    name: 'QuizComplete',
    props: true,
    component: () => import("@/views/QuizComplete.vue"),
  },
  {
    path: '/manage-subscription',
    name: 'ManageSubscriptionView',
    component: () => import("@/views/ManageSubscriptionView.vue"),
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsConditionsView',
    component: () => import("@/views/TermsConditionsView.vue"),
  },
  {
    path: '/how-to-play',
    name: 'HowToPlayView',
    component: () => import("@/views/HowToPlayView.vue"),
  },
  {
    path: '/meet-tutor',
    name: 'MeetTutorView',
    component: () => import("@/views/MeetTutorView.vue"),
  },
  {
    path: '/lessons/:lessonCategoryId',
    name: 'LessonsView',
    props: true,
    component: () => import("@/views/LessonsView.vue"),
  },
  {
    path: '/my-rank',
    name: 'MyRankView',
    component: () => import("@/views/MyRankView.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    window.scrollTo(0, 0);
  }
})

function getBaseRoute() {
  const domain = window.origin;
  const brandConfig = brandConfigMap.get(domain) ? brandConfigMap.get(domain) : brandConfigMap.get('default');
  return brandConfig.baseRoute;
}

router.beforeEach(async (to, from, next) => {
  await userAuthenticate(to, from, next);
})

// router.afterEach((to, from, next) => utagDataPost(to, from, next));

export default router
