export default class Helpers {
    static getAvatar(rank, brand, isLocked) {
        const lockedText = isLocked ? 'locked' : 'unlocked'
        try {
            return require(`../brand/${brand}/assets/avaters/${lockedText}/avatar-${rank}.webp`);
        } catch (e) {
            return require(`../brand/vc-za-en/assets/avaters/unlocked/avatar-beginner.webp`);
        }
    }

    static findJerseyImageModule(team, brand) {
        // const formattedTeamName = this.formatTextForAssetNames(team);
        // console.log(formattedTeamName)
        // try {
        //     return require(`../assets/jerseys/${formattedTeamName}-kit.png`);
        // } catch (e) {
        //     try {
        //         return require(`../assets/jerseys/${formattedTeamName}-kit.webp`);
        //     } catch (e) {
        //         return require(`../brand/${brand}/assets/jerseys/default-player-jersey.webp`);
        //     }
        // }
    }

    static findLeagueImageModule(league) {
        // const name = typeof league === "object" ? league.leagueName : league
        // const reformattedLeagueName = this.formatTextForAssetNames(name);
        // try {
        //     return require(`../assets/leagueLogos/${reformattedLeagueName}-logo-colour.webp`)
        // } catch (e) {
        //     try {
        //         return require(`../assets/leagueLogos/${reformattedLeagueName}-logo-colour.png`)
        //     } catch (e) {
        //         return require(`../assets/default-badge.webp`)
        //     }
        // }
    }
    static findTeamImageModule(teamName) {
        // const reformattedTeamName = this.formatTextForAssetNames(teamName);
        // try {
        //     return require(`../assets/teamBadges/${reformattedTeamName}-logo-icon.png`)
        // } catch (e) {
        //     return require(`../assets/default-badge.webp`)
        // }
    }

    static formatTextForAssetNames(text) {
        // const lowerCase = text.toLowerCase();
        // return lowerCase.replaceAll(' ', '-');
    }
}
