export const styles = {
    backgrounds: [
        { className: "primary-bg-colour", colour: "#E60000" },
        { className: "secondary-bg-colour", colour: "#181818" },
        { className: "tertiary-bg-colour", colour: "#F8F8F8" },
        { className: "inactive-bg-colour", colour: "#141414" },
        { className: "misc-bg-colour", colour: "#585858" },

    ],
    linearBackgrounds: [
        { className: "primary-linear-bg", linearBackground: "linear-gradient(96deg, #E60000 1.92%, #E36060 100%), #E60000" },
        { className: "secondary-linear-bg", linearBackground: "linear-gradient(92deg, #000 2.25%, #525252 100.68%)" },
    ],
    text: [
        { className: "primary-text", colour: "#ffffff" },
        { className: "secondary-text", colour: "#191919" },
        { className: "tertiary-text", colour: "#ffffff" },
        { className: "inactive-text", colour: "#666666" },
        { className: "misc-text", colour: "#E60000" },
    ],
    borders: [
        { className: "primary-border-colour", colour: "#E60000" },
        { className: "secondary-border-colour", colour: "#191919" },
        { className: "primary-free-border-colour", colour: "#44444440" },
    ],
};

