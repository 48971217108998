<template>
  <div v-if="showNav" class="footer-container">
    <div @click="onNavIconClicked('/home')" class="d-flex flex-column justify-content-center align-items-center">
      <img class="icon"
           :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav-${changActiveAndInactiveIcon(homeIsActive)}-home.webp`)"
           alt="">
      <div v-bind:class="getTextCss(homeIsActive)">Home</div>
    </div>
    <div @click="onNavIconClicked('/leaderboard')" class="d-flex flex-column justify-content-center align-items-center">
      <img class="icon"
           :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav-${changActiveAndInactiveIcon(leaderBoardIsActive)}-leaderboard.webp`)"
           alt="">
      <div v-bind:class="getTextCss(leaderBoardIsActive)">Leaderboard</div>
    </div>
    <div @click="onNavIconClicked('/settings')" class="d-flex flex-column justify-content-center align-items-center">
      <img class="icon"
           :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav-${changActiveAndInactiveIcon(settingsIsActive)}-settings.webp`)"
           alt="">
      <div v-bind:class="getTextCss(settingsIsActive)">Settings</div>
    </div>
    <div @click="onNavIconClicked('/my-rank')" class="d-flex flex-column justify-content-center align-items-center">
      <img class="icon"
           :src="require(`../../brand/${brandConfig.brand}/assets/navFooter/nav-${changActiveAndInactiveIcon(rankIsActive)}-my-rank.webp`)"
           alt="">
      <div v-bind:class="getTextCss(rankIsActive)">My Rank</div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "FooterNav",
  computed: {
    ...mapState(['brandConfig', 'user'])
  },
  data() {
    return {
      showNav: true,
      homeIsActive: false,
      leaderBoardIsActive: false,
      settingsIsActive: false,
      rankIsActive: false
    }
  },
  methods: {
    onNavIconClicked(route) {
      this.$router.push(route)
    },
    getTextCss(iconIsActive) {
      if (iconIsActive) return 'misc-text text-centre m-0'
      else return 'secondary-text text-centre m-0'
    },
    changeIconState(routeName) {
      this.homeIsActive = routeName === 'HomeView';
      this.leaderBoardIsActive = routeName === 'LeaderboardView';
      this.settingsIsActive = routeName === 'SettingsView';
      this.rankIsActive = routeName === 'RankView';
    },
    changActiveAndInactiveIcon(iconIsActive) {
      if (iconIsActive) return 'active'
      else return 'inactive'
    },
  },
  beforeMount() {
    this.changeIconState(this.$route.name)
  },
  watch: {
    async $route(to, from) {
      this.changeIconState(this.$route.name)
      if (to.name === 'RequestOtp') return this.showNav = false
      if (to.name === 'WelcomeView') return this.showNav = false
      if (to.name === 'SubmitOtp') return this.showNav = false
      if (to.name === 'SubscriptionView') return this.showNav = false
      if (to.name === 'ConfirmSubscription') return this.showNav = false
      if (to.name === 'TermsConditionsView') return this.showNav = false
      this.showNav = true
    }
  },
}
</script>

<style scoped>
.footer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
  left: 0;
  right: 0;
  height: 75px;
  background-image: url("../../brand/vc-za-en/assets/navFooter/nav-bar-sml.webp");
}

.icon {
  width: 45px;
  height: auto;
}

@media screen and (max-height: 700px) {
  .footer-container {
    height: 50px;
  }

  .icon {
    width: 25px;
  }
}
</style>
