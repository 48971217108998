import { createStore } from 'vuex'

export default createStore({
  state: {
    msisdn: undefined,
    user: undefined,
    productId: 36,
    serviceId: 'vc-fanclash-quizinc-cm-f-daily-01',
    isLoading: undefined,
    brandConfig: undefined,
    defferedPrompt: null,
    showTrainerPopup: false,
    showInviteFriend: false,
    showEditUsername: false,
    showTriviaPopup: false,
    showWhyHerePopup: false
  },
  mutations: {
    setShowWhyHerePopup(state, showWhyHerePopup) {
      state.showWhyHerePopup = showWhyHerePopup
    },
    setMsisdn(state, msisdn) {
      state.msisdn = msisdn
    },
    setUser(state, user) {
      state.user = user
    },
    setServiceId(state, serviceId) {
      state.serviceId = serviceId
    },
    setShowTriviaPopup(state, showTriviaPopup) {
      state.showTriviaPopup = showTriviaPopup
    },
    setShowEditUsername(state, showEditUsername) {
      state.showEditUsername = showEditUsername
    },
    setShowInviteFriend(state, showInviteFriend) {
      state.showInviteFriend = showInviteFriend
    },
    setIsLoading(state, isLoading) {
      state.isLoading = isLoading
    },
    setBrandConfig(state, brandConfig) {
      state.brandConfig = brandConfig
    },
    setDeferredPrompt(state, defferedPrompt) {
      state.defferedPrompt = defferedPrompt
    },
    setShowTrainerPopup(state, showTrainerPopup) {
      state.showTrainerPopup = showTrainerPopup
    }
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
